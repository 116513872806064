<template>
  <v-data-table
    :headers="headers"
    :items="customers"
    :search="search"
    :server-items-length="total"
    :options.sync="serverOptions"
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <!-- <v-toolbar-title> -->
        <v-text-field
          v-model="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <!-- </v-toolbar-title> -->
        <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          max-width="850"
        >
          <template v-slot:activator="{ on, attrs }">
            <!-- <div class="d-flex justify-space-between"> -->
            <!-- <v-text-field v-model="search" label="Search" single-line hide-details></v-text-field> -->
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
              @click="close"
            >
              สร้างลูกค้าใหม่
            </v-btn>
            <!-- </div> -->
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="6"
                    sm="6"
                    md="6"
                  >
                    <v-row>
                      <v-col
                        v-if="editedItem.member_img_logo"
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-img
                          :src="editedItem.member_img_logo"
                        >
                        </v-img>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-file-input
                          accept="image/png, image/jpeg, image/bmp"
                          label="โลโก้ร้าน"
                          v-model="editedItem.file">
                        </v-file-input>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-radio-group
                          v-model="editedItem.set_price"
                          label="กำหนดเรทราคา*">
                          <v-radio value="Y" label="ใช่"></v-radio>
                          <v-radio value="N" label="ไม่ใช่"></v-radio>
                        </v-radio-group>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-text-field
                          type="text"
                          v-model="editedItem.member_shop_name"
                          label="ชื่อร้าน"
                        ></v-text-field>
                        <small
                          class="validate-err"
                          v-for="(err,index) in v$.editedItem.member_shop_name.$silentErrors"
                          v-if="v$.editedItem.member_shop_name.$error">{{ index > 0 ? ', ' : '' }}
                          {{ err.$message }}</small>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-text-field
                          type="email"
                          v-model="editedItem.member_email"
                          label="อีเมลเข้าใช้งาน"
                        ></v-text-field>
                        <small
                          class="validate-err"
                          v-for="(err,index) in v$.editedItem.member_email.$silentErrors"
                          v-if="v$.editedItem.member_email.$error">{{ index > 0 ? ', ' : '' }}
                          {{ err.$message }}</small>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-text-field
                          type="password"
                          v-model="editedItem.password"
                          label="รหัสผ่าน"
                        ></v-text-field>
                        <small
                          class="validate-err"
                          v-for="(err,index) in v$.editedItem.password.$silentErrors"
                          v-if="v$.editedItem.password.$error">{{ index > 0 ? ', ' : '' }}
                          {{ err.$message }}</small>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-text-field
                          type="password"
                          v-model="editedItem.password_confirmation"
                          label="ยืนยันรหัสผ่าน"
                        ></v-text-field>
                        <small
                          class="validate-err"
                          v-for="(err,index) in v$.editedItem.password_confirmation.$silentErrors"
                          v-if="v$.editedItem.password_confirmation.$error">{{
                            index > 0 ? ', ' : ''
                          }}
                          {{ err.$message }}</small>
                      </v-col>

                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-select
                          :items="customer_types"
                          v-model="editedItem.member_type_id"
                          item-text="member_type_name"
                          item-value="member_type_id"
                          dense
                          label="กลุ่มลูกค้า">
                        </v-select>
                        <small
                          class="validate-err"
                          v-for="(err,index) in v$.editedItem.member_type_id.$silentErrors"
                          v-if="v$.editedItem.member_type_id.$error">{{ index > 0 ? ', ' : '' }}
                          {{ err.$message }}</small>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="6"
                    md="6"
                  >
                    <v-row>

                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-text-field
                          type="text"
                          v-model="editedItem.member_name"
                          label="ชื่อ-นามสกุล"
                        ></v-text-field>
                        <small
                          class="validate-err"
                          v-for="(err,index) in v$.editedItem.member_name.$silentErrors"
                          v-if="v$.editedItem.member_name.$error">{{ index > 0 ? ', ' : '' }}
                          {{ err.$message }}</small>
                      </v-col>

                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-text-field
                          v-model="editedItem.member_tele"
                          label="เบอร์โทรศัพท์"
                        ></v-text-field>
                        <small
                          class="validate-err"
                          v-for="(err,index) in v$.editedItem.member_tele.$silentErrors"
                          v-if="v$.editedItem.member_tele.$error">{{ index > 0 ? ', ' : '' }}
                          {{ err.$message }}</small>
                      </v-col>

                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-text-field
                          v-model="editedItem.member_line_id"
                          label="Line Id"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-textarea
                          v-model="editedItem.member_shop_address"
                          label="ที่อยู่"
                        ></v-textarea>
                      </v-col>


                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-text-field
                          v-model="editedItem.member_facebook_url"
                          label="Facebook URL"
                        ></v-text-field>
                      </v-col>

                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-text-field
                          v-model="editedItem.member_instragram"
                          label="Instagram"
                        ></v-text-field>
                      </v-col>


                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-text-field
                          v-model="editedItem.member_identification"
                          label="เลขบัตรประชาชน"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                text
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                text
                @click="save"
              >
                Save
              </v-btn>
            </v-card-actions>

            <v-card-text>

              <div
                v-if="editedItem.member_id && editedItem.set_price === 'Y' && products.length > 0">
                <!--                        <h3>กำหนดเรทราคาตามประเภทสินค้า</h3>-->
                <v-select v-if="products.length > 0"
                          label="กำหนดเรทราคาตามประเภทสินค้า"
                          :items="products"
                          item-value="product_type_id"
                          item-text="product_type_name"
                          v-model="product_select"
                          placeholder="เลือกรายการประเภทสินค้าเพื่อกำหนดราคา"
                          @input="ProductSelect()">
                </v-select>
                <br>
                <br>
                <h4 v-if="product_type_set_price && product_type_set_price.length > 0">
                  ตารางกำหนดเรทราคาลูกค้า</h4>
                <div v-if="product_type_set_price && product_type_set_price.length > 0">
                  <table class="table">
                    <thead>
                    <tr>
                      <th class="text-center" width="20">#</th>
                      <th scope="col">ประเภทสินค้า</th>
                      <th scope="col" style="text-align: center;font-size: 15px">
                        <span>*กำหนดเรทราคาลูกค้า</span></th>
                      <th scope="col"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-if="editedItem" v-for="(product,index) in product_type_set_price">
                      <td class="text-center" scope="row">{{ index + 1 }}</td>
                      <td>
                        {{ product.product_type_name }}
                      </td>
                      <td style="font-size: 18px">
                        <!--                        <input name="product_type_id[]" v-model="product.product_type_id"-->
                        <!--                               type="hidden">-->
                        <v-text-field
                          type="number"
                          v-model="product.set_price.price"
                          min="0"
                          placeholder="กำหนดราคาลูกค้า"
                        ></v-text-field>
                      </td>
                      <td class="text-center">
                        <v-icon
                          class="mr-2"
                          @click="Delete(index)"
                          small
                          color="danger">
                          {{ icons.mdiDelete }}
                        </v-icon>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <center>
                  <v-btn
                    @click="update()"
                    class="success-badge mt-5">บันทึก
                  </v-btn>
                </center>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="dialogDelete"
          max-width="600px"
        >
          <v-card>
            <v-card-title class="text-h5 mb-5">
              Are you sure you want to delete this item?
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                outlined
                @click="closeDelete"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                @click="deleteItemConfirm"
              >
                OK
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="dialogDeleteLine"
          max-width="600px"
        >
          <v-card>
            <v-card-title class="text-h5 mb-5">
              Are you sure you want to delete this Line's data?
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                outlined
                @click="closeDeleteLine"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                @click="deleteLineConfirm">
                OK
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:item.index="{ index }">
      {{ ((serverOptions.page - 1) * serverOptions.itemsPerPage) + index + 1 }}
    </template>
    <template v-slot:item.status="{ item }">
      <v-container
        class="px-0"
        fluid
      >
        <v-checkbox
          value="Y"
          @change="changeStatus(item.member_id)"
          v-model="item.user.status"
        ></v-checkbox>
      </v-container>
    </template>
    <template v-slot:item.movement="{ item }">

      <router-link
        class="none-decoration"
        :to="{ name: 'pages-customer-movement' , params: { id : item.member_id } }">
        <v-btn
          small
          class="router-link-decoration-none"
          color="warning"
          @click="showMovement(item)"
          dark
        >
          ความเคลื่อนไหว
        </v-btn>
      </router-link>
    </template>
    <template v-slot:item.set_price="{ item }">
      <span v-if="item.set_price === 'Y'">ใช่</span>
      <span v-if="item.set_price !== 'Y'">ไม่</span>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        {{ icons.mdiPencil }}
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        {{ icons.mdiDelete }}
      </v-icon>

      <v-btn
        @click="deleteLine(item)"
        text>
        ล้างข้อมูลไลน์
      </v-btn>
    </template>
    <template v-slot:no-data>
      <!-- <v-btn
        color="primary"
        @click="initialize"
      >
        Reset
      </v-btn> -->
    </template>
  </v-data-table>
</template>

<style>
.none-decoration {
  text-decoration: none;
}
</style>
<script>
import {mdiPencil, mdiDelete} from '@mdi/js'
// import config from '../../../config'
import instance from '@/axios.service'
import instance_member from '@/services/member'
import instance_member_type from '@/services/memberType'
import Swal from "sweetalert2";
import {useVuelidate} from '@vuelidate/core'
import {required, email, minLength, sameAs} from '@vuelidate/validators'

export default {
  setup() {
    return {
      icons: {
        mdiPencil,
        mdiDelete,
      },
      v$: useVuelidate()
    }
  },
  data: () => ({
    serverOptions: {
      page: 1,
      itemsPerPage: 10,

    },
    product_type_set_price: [],
    total: 0,
    search: '',
    sortBy: '',
    sortDesc: '',
    customers: [],
    customer_types: [],
    dialog: false,
    dialogDelete: false,
    dialogDeleteLine: false,
    headers: [
      {
        text: 'ลำดับ',
        align: 'start',
        sortable: false,
        value: 'index',
      },
      {text: 'ชื่อ-นามสกุล ลูกค้า', value: 'member_name'},
      {text: 'เครดิตสินค้า(เคส)', value: 'credit_product_amt'},
      {text: 'ชื่อร้าน', value: 'member_shop_name'},
      {text: 'กำหนดราคา', value: 'set_price'},
      {text: 'จำนวนการสั่งซื้อ', value: 'orders_count'},
      {text: 'อนุมัติ(เปิด/ปิด)', value: 'status', sortable: false},
      {text: 'ความเคลื่อนไหว', value: 'movement', sortable: false},
      {text: 'แก้ไขข้อมูล', value: 'actions', sortable: false},
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      member_img_logo: null,
      member_shop_name: '',
      member_email: '',
      member_type_id: null,
      member_name: '',
      member_tele: '',
      member_shop_address: '',
      member_line_id: '',
      member_facebook_url: '',
      member_instragram: '',
      member_identification: '',
      set_price: 'N',
      password: '',
      password_confirmation: '',
    },
    defaultItem: {
      member_img_logo: null,
      member_shop_name: '',
      member_email: '',
      member_type_id: null,
      member_name: '',
      member_tele: '',
      member_shop_address: '',
      member_line_id: '',
      member_facebook_url: '',
      member_instragram: '',
      member_identification: '',
      set_price: 'N',
      password: '',
      password_confirmation: '',
    },
    product_set_price: [],
    set_price: 'N',
    product_select: null,
    products: [],
  }),
  validations() {
    var validate = {
      editedItem: {
        password: {},
        password_confirmation: {},
        member_shop_name: {required,},
        member_name: {required,},
        member_email: {required, email},
        member_type_id: {required},
        member_tele: {required,},
      }
    }
    if (this.editedIndex == -1) {
      validate.editedItem.password = {required, minLength: minLength(8)};
      validate.editedItem.password_confirmation = {sameAsPassword: sameAs(this.editedItem.password)};
    }
    return validate
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'เพิ่ม ลูกค้า' : 'แก้ไข ลูกค้า'
    },
  },

  watch: {
    serverOptions: {
      handler() {
        this.getCustomer()
      },
    },
    search: {
      handler() {
        this.getCustomer()
      },
    },
    // dialog(val) {
    //   val || this.close()
    // },
    // dialogDelete(val) {
    //   val || this.closeDelete()
    // },
  },

  created() {
    // this.initialize()
  },
  mounted() {
    this.getCustomer()
    this.getCustomerType()
  },

  methods: {
    Delete(index) {
      this.product_type_set_price.splice(index, 1);
    },
    ProductSelect() {
      this.$store.state.isLoading = true
      var data = {
        product_type_id: this.product_select,
        member_id: this.editedItem.member_id,
      }
      instance_member.setPrice(data).then(res => {
        if (res.data === 0) {

          var tmp = {};
          for (var i = 0; i < this.products.length; i++) {
            if (this.products[i].product_type_id == this.product_select) {
              tmp = this.products[i];
              break;
            }
          }
          if (!tmp.set_price) {
            tmp.set_price = {}
            tmp.set_price.price = '';
          }
          this.product_type_set_price.push(tmp)
        } else {

        }
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false
      })
    },
    editItem(item) {
      this.v$.$reset();
      instance_member.showMember(item.member_id).then(res => {

        this.editedIndex = this.customers.indexOf(item)
        this.editedItem = {...item}
        this.products = res.data.data.product_set_price;
        this.product_type_set_price = res.data.data.product_type_set_price;
        this.dialog = true

      }).catch(err => {

      })


    },

    deleteItem(item) {
      this.editedIndex = this.customers.indexOf(item)
      this.editedItem = {...item}
      this.dialogDelete = true
    },
    deleteLine(item) {
      this.editedIndex = this.customers.indexOf(item)
      this.editedItem = {...item}
      this.dialogDeleteLine = true;
    },

    deleteItemConfirm() {
      this.$store.state.isLoading = true
      var data = {
        id: this.editedItem.member_id
      }
      instance_member.memberDestroy(data).then(res => {

        setTimeout(function () {
          Swal.fire({
            icon: 'success',
            title: res.data.message,
            showConfirmButton: false,
            timer: 1500
          })
        }, 150);
        this.getCustomer()
        this.closeDelete()

      }).catch(err => {
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด !!',
          text: err.message,
        })
        this.$store.state.isLoading = false

      })
    },
    deleteLineConfirm() {
      this.$store.state.isLoading = true
      instance_member.memberDeleteLine(this.editedItem.member_id).then(res => {

        setTimeout(function () {
          Swal.fire({
            icon: 'success',
            title: res.data.message,
            showConfirmButton: false,
            timer: 1500
          })
        }, 150);
        this.getCustomer()
        this.closeDeleteLine()
      }).catch(err => {
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด !!',
          text: err.message,
        })
        this.$store.state.isLoading = false
      })
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.v$.$reset()
        this.editedItem = {...this.defaultItem}
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = {...this.defaultItem}
        this.editedIndex = -1
      })
    },
    closeDeleteLine() {
      this.dialogDeleteLine = false
      this.$nextTick(() => {
        this.editedItem = {...this.defaultItem}
        this.editedIndex = -1
      })
    },
    update() {
      this.$store.state.isLoading = true
      var product_type_ids = [];
      var prices = [];
      for (var i = 0; i < this.product_type_set_price.length; i++) {
        product_type_ids.push(this.product_type_set_price[i].product_type_id)
        prices.push(this.product_type_set_price[i].set_price ? this.product_type_set_price[i].set_price.price : this.product_type_set_price[i].price)
      }

      var data = {
        member_id: this.editedItem.member_id,
        product_type_id: product_type_ids,
        price: prices
      }
      instance_member.memberSetPrice(data).then(res => {
        this.$store.state.isLoading = false
        this.close()
        setTimeout(function () {
          Swal.fire({
            icon: 'success',
            title: res.data.message,
            showConfirmButton: false,
            timer: 1500
          })
        }, 150)
      }).catch(err => {
        this.$store.state.isLoading = false
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด !!',
          text: err.message,
        })
      });
    },
    async save() {
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) return
      this.$store.state.isLoading = true

      if (this.editedIndex > -1) {
        instance_member.memberUpdate(this.editedItem).then(res => {
          if (res.data.success) {
            setTimeout(function () {
              Swal.fire({
                icon: 'success',
                title: res.data.message,
                showConfirmButton: false,
                timer: 1500
              })
            }, 150);
            this.getCustomer()
            this.close()
          } else {

            this.$store.state.isLoading = false
            Swal.fire({
              icon: 'error',
              title: 'เกิดข้อผิดพลาด !!',
              text: res.message ?? res.data.message,
            })
          }
        }).catch(err => {
          Swal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด !!',
            text: err.message,
          })
          this.$store.state.isLoading = false

        })
      } else {

        instance_member.memberStore(this.editedItem).then(res => {
          if (res.data.success) {
            setTimeout(function () {
              Swal.fire({
                icon: 'success',
                title: res.data.message,
                showConfirmButton: false,
                timer: 1500
              })
            }, 150)
            this.getCustomer()
            this.close()
          } else {
            Swal.fire({
              icon: 'error',
              title: 'เกิดข้อผิดพลาด !!',
              text: res.message ?? res.data.message,
            })

            this.$store.state.isLoading = false

          }

        }).catch(err => {
          Swal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด !!',
            text: err.message,
          })

          this.$store.state.isLoading = false
        })

      }
    },
    changeStatus(id) {
      this.$store.state.isLoading = true;
      instance_member.memberUpdateStatus(id).then(res => {

        Swal.fire({
          icon: 'success',
          title: res.data.message,
          showConfirmButton: false,
          timer: 1500
        })
        this.$store.state.isLoading = false

      }).catch(err => {
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด !!',
          text: err.message,
        })
        this.$store.state.isLoading = false
      });
    },
    showMovement(item) {
      // this.$router.push({name: 'pages-customer-movement', params: {id: item.member_id}})/**/
    },
    getCustomerType() {
      instance_member_type.getAll().then(res => {
        this.customer_types = res.data.data
      }).catch(err => {

      })
    },
    getCustomer() {
      this.$store.state.isLoading = true;
      var data = {
        search: this.search,
        page: this.serverOptions.page,
        size: this.serverOptions.itemsPerPage,
        date: this.$route.query.date ? this.$route.query.date : '',
        status: this.$route.query.status ? this.$route.query.status : '',
        sortBy: this.sortBy ?? "",
        sortDesc: this.sortDesc ?? ''
      }
      instance_member.getMember(data).then(res => {
        this.customers = res.data.data.data;
        this.total = res.data.data.total
        this.serverOptions.itemsPerPage = this.serverOptions.itemsPerPage == -1 ? -1 : res.data.data.per_page
        this.serverOptions.page = res.data.data.current_page
        this.$store.state.isLoading = false;

      }).catch(err => {

        this.$store.state.isLoading = false;
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด !!',
          text: err.message,
        })
      });
    },
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
</style>
